.qustionSection {
  position: relative;
  justify-content: center;
}
.qustionSection:hover {
  cursor: pointer;
}
.question {
  max-width: calc(100vw - 12.7rem);
}
.answer {
  opacity: 0;
  width: 100%;
}
.article {
  border-top: 2px solid var(--black);
}
.article:last-child {
  border-bottom: 2px solid var(--black);
}
.cross {
  width: 2rem;
  height: 2rem;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
}
.cross:before,
.cross:after {
  position: absolute;
  left: 50%;
  margin: 0 -1px;
  content: '';
  height: 100%;
  width: 2px;
  background-color: #000;
  transition: transform 0.3s ease;
}
.cross:before {
  transform: rotate(90deg);
}
.close:after {
  transform: rotate(-90deg);
}
.close:before {
  transform: rotate(-90deg);
}
.visibleSection {
  display: none;
}
@media (min-width: 768px) {
  .answer {
    width: calc(50% - var(--xs));
  }
  .cross {
    width: 3rem;
    height: 3rem;
  }
  .question {
    max-width: calc(100vw - 20rem);
  }
}
