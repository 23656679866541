.container {
  position: relative;
}
.day {
  display: flex;
  gap: 1rem;
  padding: 2rem var(--xs);
}
.item {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 2rem 0;
  position: relative;
}
.container::before,
.item::before {
  position: absolute;
  left: var(--xs);
  right: var(--xs);
  top: -1px;
  height: 2px;
  background-color: var(--black);
}
.item::before {
  content: '';
}

@media (min-width: 768px) {
  .day {
    flex-direction: column;
  }
  .container {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .container::before {
    content: '';
  }
  .item {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
}
