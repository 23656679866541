.track {
  position: relative;
  padding-bottom: calc(var(--xxl) * 2 + 4.5rem);
}
.track:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  width: 2px;
  background-color: var(--black);
  z-index: -1;
}
.track::after {
  content: '';
  position: absolute;
  top: 0;
  left: var(--xs);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--front);
}
.name {
  height: 14rem;
}
@media (min-width: 768px) {
  .track::after {
    width: 3rem;
    height: 3rem;
  }
  .name {
    height: 24rem;
  }
}
