.image {
  padding: var(--xs) var(--xs) 0 var(--xs);
}
@media (min-width: 768px) {
  .image {
    position: relative;
    order: 2;
  }
  .image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
    background-color: var(--black);
  }
}
