.right {
  margin-top: -5.6rem;
}

.right * {
  margin: 2rem 0;
}
.image {
  width: 80%;
}

@media (min-width: 768px) {
  .image {
    width: 90%;
  }
  .right {
    margin-top: 2rem;
  }
}
