.benefits {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.benefit {
  width: 50%;
}
.image {
  padding: 0 3vw;
  margin: 0 auto;
  max-width: 40rem;
}
@media (min-width: 520px) {
  .benefit {
    width: 33%;
  }
}
@media (min-width: 768px) {
  .benefits {
    justify-content: space-between;
  }
  .benefit {
    width: 25%;
  }
}
