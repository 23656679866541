.text {
  display: flex;
  flex-direction: column;
  min-height: 17rem;
  padding: 2rem 1.5rem;
  align-items: stretch;
  justify-content: space-between;
}
.text > h3 {
  transition: transform 0.3s ease;
}
.image {
  max-width: 34rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem 0 1rem;
  transition: transform 0.3s ease;
}
.active {
  outline: 2px solid black;
}
@media (hover: hover) {
  .card:not(.active):hover > .image {
    transform: scale(0.98) translateZ(0);
  }
  .card:not(.active):hover > .text > h3 {
    transform: translate3d(0, -5px, 0);
  }
}

@media (min-width: 768px) {
  .text {
    min-height: 21rem;
    padding: 2rem 3rem 3rem 3rem;
  }
}
