.noactive {
  opacity: 0.5;
  pointer-events: none;
}
.name {
  flex-grow: 1;
}
.ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ul > *,
.price > * {
  margin-bottom: var(--xxxs);
}
.li {
  padding-left: 2.2rem;
  position: relative;
}
.li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.no {
  opacity: 0.33;
}
.yes::before {
  background-image: url('./yes.svg');
}
.no::before {
  background-image: url('./no.svg');
}
.price {
  text-align: center;
  min-height: 5rem;
}
.price h4 span {
  position: relative;
  padding-left: 0.36em;
}
.price h4 span::before {
  content: '$';
  position: absolute;
  font-size: 0.6em;
  left: 0;
  top: 0;
}
.button {
  display: block;
}
.bottom {
  position: relative;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bottom p {
  flex-grow: 1;
}
.barcode {
  width: 38%;
  height: auto;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .price {
    min-height: 8rem;
  }
  .bottom {
    min-height: 13rem;
  }
  .li {
    padding-left: 3.2rem;
    position: relative;
  }
  .li::before {
    width: 2.2rem;
    height: 2.2rem;
  }
}
